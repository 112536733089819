<template>
<div class="changePassword page">
  <headers title="修改密码"></headers>
  <div class="scroll">
    <ul class="list">
      <li class="flexCenter" v-for="(item,index) in form_list" :key="index">
        <div class="label">{{item.label}}</div>
        <div class="right">
          <p v-if="index === 0" class="phone">{{item.value}}</p>
          <van-field
              v-else
              v-model="item.value"
              label=""
              :placeholder="'请输入'+item.label"
              type="password"
          />
        </div>
      </li>
    </ul>
    <div style="margin: 25px 15px;">
      <van-button round block type="info" native-type="submit" color="linear-gradient(180deg,#9eb0ff,#4969ff)" @click="submit()">提交</van-button>
    </div>
  </div>
</div>
</template>

<script>
import headers from "@/components/headers.vue";
import {Toast} from "vant";

export default {
  name: "changePassword",
  components: {headers},
  data() {
    return {
      form_list: [
        {
          label: '+86',
          key: 'account',
          value: '',
        },
        {
          label: '原始密码',
          key: 'old_password',
          value: ''
        },
        {
          label: '新密码',
          key: 'password',
          value: ''
        },
      ],
    }
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo(){
      $api.password({
        token: this.$store.getters["getToken"]
      }).then((res) => {
          this.form_list[0].value = res.data.account;
      })
    },
    submit(){
      let form = {}
      for (var i = 0; i < this.form_list.length; i++) {
        if (this.form_list[i].value === '') {
          Toast({
            message: this.form_list[i].label + '不能为空',
            duration: 1500
          });
          return false;
        }
        form[this.form_list[i].key] = this.form_list[i].value
      }
      $api.set_info({
        token: this.$store.getters["getToken"],
        ...form
      }).then(res => {
        Toast('修改成功')
        setTimeout(()=>{
          this.$router.back()
        },1000)
      })
    }
  }
}
</script>

<style lang="scss">
.changePassword {
    .scroll {
      .list {
        li {
          padding: 10px 16px;
          height: 50px;
          .label {
            font-size: 16px;
            position: relative;
            width: 80px;
            text-align: left;
            border-right: 1px solid #e5e5e5;
            margin-right: 15px;
            height: 100%;
            display: flex;
            align-items: center;
          }
          .phone {
            font-size: 15px;
            padding-left: 15px;
          }
        }
      }
    }
}
</style>